<script setup>
const props = defineProps({
  azure: {
    type: Boolean,
    default: false
  },
  showWorkingHours: {
    type: Boolean,
    default: false,
  },
  customContent: {
    type: Boolean,
    default: () => false,
  },
  isInline: {
    type: Boolean,
    default: () => false,
  }
});

const siteConfig = useSiteConfig();
const blend = useBlend();
const nuxtApp = useNuxtApp();
const {onOpenCallPopup} = useCallPopup();

const openPopup = ref(false)
const route = useRoute();
const contactPhone = computed(() => {
  if (route.name === 'static-offer-rp') {
    return siteConfig.rpContactPhone;
  } else if (
    route.name !== 'static-about' &&
    route.name !== 'static-contact' &&
    route.name !== 'index'
  ) {
    return siteConfig.contactPhoneSec;
  } else {
    return siteConfig.contactPhone;
  }
});

const call = () => {
  if (nuxtApp.$isMobile() && isInWorkingHours()) {
    window.location.href = 'tel:' + contactPhone.value;
    return;
  }
  openPopup.value = true
}


blend.blendEffect(openPopup, false, true, true,
  () => {
    openPopup.value = false;
  }
)

onOpenCallPopup(() => openPopup.value = true);
</script>

<template>
  <div :class="{'inline': isInline}" class="cursor-pointer" @click="call">
    <div v-if="!customContent" class="gap-3 items-center hidden lg:flex">
      <div class="text-base text-dark-gray flex items-center gap-1">
        <IconPhone :stroke="azure ? 'rgb(10 102 168)' : ''" />
        <div :class="{'text-azure font-normal' : azure}">{{contactPhone}}</div>
      </div>
      <div v-if="!showWorkingHours" class="text-xs text-gray-400 font-extralight">{{ siteConfig.workingHours.start }} - {{ siteConfig.workingHours.end }}</div>
    </div>
    <div :class="{'inline': isInline}"  v-else>
      <slot />
    </div>
    <div v-if="!customContent" class="flex lg:hidden">
      <div class="flex items-center justify-center w-[34px] h-[34px] bg-gray-700 rounded-full">
        <IconPhone stroke="white" size="24" />
      </div>
    </div>
  </div>
  <Teleport to="body">
    <PopupCall :contactPhone="contactPhone" :visible="openPopup" @dismiss="openPopup = false" />
  </Teleport>
</template>
